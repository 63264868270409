import { BaseCard } from '@orbica/component-sdk';
import { Tooltip } from '@orbica/component-sdk';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AoiRunIndexResultPill } from '@Components/EntityDetails/AoiRunIndexResultPill';
import { SpectralIndexPill } from '@Components/EntityDetails/SpectralIndexPill';
import { Navigation } from '@Services/navigation/Navigation';
const StyledBaseCard = styled(BaseCard) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    > * {
        cursor: pointer;
    }
`;
const BoldRightAlignedText = styled.span `
    font-weight: bold;
    float: right;
`;
export const TabularAoiRunResult = (props) => {
    const navigate = useNavigate();
    const { jobId, aoiId } = useParams();
    const handleNavigation = () => {
        navigate(Navigation.schedule.aoiRunResult.getUrl(jobId, aoiId, props.runResult.id));
    };
    console.log(props);
    const labelHtml = props.themeName === 'custom' ? (React.createElement(SpectralIndexPill, { shortName: props.runResult.spectral_index })) :
        (React.createElement("span", { className: TypographyStyles.baseLight }, props.runResult.label));
    return (React.createElement(Tooltip, { title: "View Result" },
        React.createElement(StyledBaseCard, { onClick: handleNavigation },
            labelHtml,
            React.createElement(AoiRunIndexResultPill, { resultValue: props.runResult.value }),
            props.runResult.number_of_significant_changes > 0 && (React.createElement(BoldRightAlignedText, null,
                props.runResult.number_of_significant_changes,
                " changes")))));
};
