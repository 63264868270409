import { Label, RadioButton, Slider, Select, SelectOption } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import cx from 'classnames';
import React, { useState } from 'react';
import { TitleAndDescription } from './TitleAndDescription';
import styled from 'styled-components';
const sliderContainer = styled('div') `
    display: flex;
    flex-direction: column;
    gap: 15px; // Space between slider and description
`;
const sliderWrapperWithLabels = styled('div') `
    display: flex;
    align-items: center;
    gap: 10px; // Space between labels and slider
`;
const sliderLabel = styled('div') `
    font-size: 140px; // Adjust as needed
    color: #333; // Adjust as needed
    width: 40px; // Ensures consistent label width
    text-align: center; // Align text properly
`;
const notificationOptions = [
    { label: 'Weekly' },
    { label: 'Bi-Weekly' },
    { label: 'Monthly' },
    { label: 'Quarterly' },
    { label: 'Annually' },
];
export const ReportStage = (props) => {
    const sensitivityLabels = ['Very Low', '', '', '', '', '', '', '', '', '', 'Very High'];
    const changeTypeOptionMap = {
        POSITIVE: 'Positive',
        NEGATIVE: 'Negative',
        BOTH: 'Both',
    };
    const [sensitivity, setSensitivity] = useState(props.sensitivityValue || 5);
    const [sensitivityLabel, setSensitivityLabel] = useState(props.sensitivityLabel);
    const [selectedCadenceLabel, setSelectedCadenceLabel] = useState(props.reportCadenceLabel);
    const [changeType, setChangeType] = useState();
    const selectedchangeTypeDisplayName = changeType ? changeTypeOptionMap[changeType] : 'Select a change type';
    const handleSliderChange = (event, value) => {
        const label = sensitivityLabels[value - 1];
        setSensitivity(value);
        setSensitivityLabel(label);
        props.onSetSensitivity(value);
        props.onSetSensitivityLabel(label);
    };
    const handleChangeReportCadence = (label) => {
        setSelectedCadenceLabel(label);
        props.onSetReportCadenceLabel(label);
    };
    const handleSelectChange = (value) => {
        setChangeType(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: GridContentStyles.max },
            React.createElement(Select, { label: "What type of change are you interested in?", onChange: (event, value) => handleSelectChange(value), value: selectedchangeTypeDisplayName, placeholder: "Select a report type", required: true }, Object.entries(changeTypeOptionMap).map(([value, displayName]) => {
                return (React.createElement(SelectOption, { key: value, value: value }, displayName));
            }))),
        React.createElement(TitleAndDescription, { title: "Set the change detection frequency and notification schedule for your area" }),
        React.createElement("div", { className: cx(GridContentStyles.max, GridStyles.dialogGrid) },
            React.createElement(Label, { label: "How often do you want to be notified?" }),
            React.createElement("div", null, notificationOptions.map((option) => (React.createElement(RadioButton, { key: option.label, label: option.label, checked: selectedCadenceLabel === option.label, onChange: () => handleChangeReportCadence(option.label) }))))),
        React.createElement("div", { className: GridContentStyles.max },
            React.createElement("div", { className: GridContentStyles.sliderContainer },
                React.createElement("div", { className: GridContentStyles.sliderWrapperWithLabels },
                    React.createElement(Slider, { min: 0, max: 10, value: sensitivity, onChange: handleSliderChange, valueLabelFormat: (value) => sensitivityLabels[value], containerClassName: GridContentStyles.mediumSmall, label: "Sensitivity of Change Detection" }),
                    React.createElement("span", null, "Low"),
                    React.createElement("span", { style: { float: 'right' } }, "High"))))));
};
