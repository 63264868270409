import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
export function mapComparisonResult(gqlResult) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return Object.assign(Object.assign({}, gqlResult), { baseline_image_url: (_b = (_a = gqlResult.run_result_bl) === null || _a === void 0 ? void 0 : _a.baseline_image) !== null && _b !== void 0 ? _b : null, baseline_gif_url: (_d = (_c = gqlResult.run_result_bl) === null || _c === void 0 ? void 0 : _c.gif_url) !== null && _d !== void 0 ? _d : null, monitoring_image_url: (_f = (_e = gqlResult.run_result_si) === null || _e === void 0 ? void 0 : _e.monitoring_image) !== null && _f !== void 0 ? _f : null, monitoring_image_rgb_url: (_j = (_h = (_g = gqlResult.run_result_si) === null || _g === void 0 ? void 0 : _g.aoi_run) === null || _h === void 0 ? void 0 : _h.monitoring_image_rgb) !== null && _j !== void 0 ? _j : null, result_zip_url: (_l = (_k = gqlResult.run_result_si) === null || _k === void 0 ? void 0 : _k.result_zip_url) !== null && _l !== void 0 ? _l : null, spectral_index: (_o = (_m = gqlResult.aoi_run_result) === null || _m === void 0 ? void 0 : _m.spectral_index) !== null && _o !== void 0 ? _o : null, chng_report_pdf: (_q = (_p = gqlResult.run_result_si) === null || _p === void 0 ? void 0 : _p.chng_report_pdf) !== null && _q !== void 0 ? _q : null, chng_report_zip_url: (_s = (_r = gqlResult.run_result_si) === null || _r === void 0 ? void 0 : _r.chng_report_zip_url) !== null && _s !== void 0 ? _s : null, chng_appendix_pdf: (_u = (_t = gqlResult.run_result_si) === null || _t === void 0 ? void 0 : _t.chng_appendix_pdf) !== null && _u !== void 0 ? _u : null });
}
export function transformFilterRunResultMetadataResponse(response) {
    const mappedMetadata = response.run_result_metadata.map((m) => (Object.assign(Object.assign({}, m), { epochTime: Math.round(DateTime.fromISO(m.acquisition_time).toSeconds()) })));
    return Object.assign(Object.assign({}, response), { run_result_metadata: mappedMetadata });
}
export function transformGetRunResultResponse(response) {
    if (_isNil(response.aoi_run_result_by_pk)) {
        return null;
    }
    const runResult = response.aoi_run_result_by_pk;
    const theme = runResult.aoi_run.aoi.job.theme;
    const spectral_index = runResult.spectral_index;
    let themeSpectralIndex;
    if (theme.name === 'custom') {
        themeSpectralIndex = {
            spectral_index: spectral_index,
            label: spectral_index,
        };
    }
    else {
        const themeIndex = theme.theme_spectral_indices.find((tsi) => tsi.spectral_index.asi_short_name === spectral_index);
        themeSpectralIndex = {
            spectral_index: spectral_index,
            label: themeIndex.name,
            description: themeIndex.description,
        };
    }
    return {
        id: runResult.id,
        aoi_run_id: runResult.aoi_run_id,
        created_at: runResult.created_at,
        acquisition_time: runResult.acquisition_time,
        spectral_index: spectral_index,
        theme_spectral_index: themeSpectralIndex,
        value: runResult.value,
        aoi_run_status: runResult.aoi_run.status,
        aoi_run_run_date: runResult.aoi_run.run_date,
        aoi_run_scheduled_date: runResult.aoi_run.scheduled_date,
        aoi_geom: runResult.aoi_run.aoi.aoi_geom,
        aoi_area: runResult.aoi_run.aoi.area,
        aoi_name: runResult.aoi_run.aoi.name,
        job_baseline: runResult.aoi_run.aoi.job.baseline,
        job_theme: theme,
    };
}
