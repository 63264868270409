var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, Checkbox, Label, Select, SelectOption, PrimaryButton } from '@orbica/component-sdk';
import { DateRangePicker, Slider } from '@orbica/component-sdk';
import { DateTime } from 'luxon';
import { ConfirmCancelProcessDialog } from '@Components/Dialogs/ConfirmCloseDialog';
import { useGetJobQuery } from '@Data/jobs/Api';
import { aoisReportApi } from '@Data/aois/Api';
import { useGetAoiRunsQuery } from '@Data/aoiRuns/Api';
import { SecondaryInfoContainer } from '@Components/Containers';
import styled from 'styled-components';
import cx from 'classnames';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { skipToken } from '@reduxjs/toolkit/query';
const StyledLabelContainer = styled('div') `
    display: grid;
    column-gap: ${GridStyles.columnGap};
    row-gap: ${GridStyles.rowGap};
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
const StyledIndicesContainer = styled(StyledLabelContainer) `
    margin-top: 24px;
`;
const StyledIndicesDescription = styled('div') `
    display: flex;
    flex-direction: column;

    .learnMore {
        display: flex;
        align-items: baseline;
        a {
            margin-right: 3px;
        }
    }
`;
const StyledSelectedIndicesContainer = styled('div') `
    display: flex;
    gap: 4px;
    margin-top: 2px;
`;
const StyledValidationDialogContent = styled('div') `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    color: #333;
    width: 100%;

    .message {
        margin-bottom: 16px;
        text-align: center;
    }
`;
const StyledButtonContainer = styled('div') `
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SliderContainer = styled('div') `
    display: flex;
    align-items: center;
    gap: 16px;
`;
const SliderWrapper = styled('div') `
    flex: 1;
`;
const SensitivityDescription = styled('div') `
    flex: 0.5;
    text-align: left;
`;
export const AoiReportDialog = (props) => {
    const { aoiId, jobId, onSetStartDate, onSetEndDate } = props;
    const { user } = useAuth0();
    const { data: jobDetails, isLoading, error } = useGetJobQuery(jobId);
    const { data: aoiRuns, isFetching } = useGetAoiRunsQuery(aoiId
        ? {
            aoi_id: aoiId,
        }
        : skipToken);
    const [filteredRuns, setFilteredRuns] = useState([]);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState(false);
    const [spectralIndices, setSpectralIndices] = useState([]);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [methodType, setMethodType] = useState();
    const [changeType, setChangeType] = useState();
    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [sensitivity, setSensitivity] = useState(props.sensitivityValue || 5);
    const [sensitivityLabel, setSensitivityLabel] = useState(props.sensitivityLabel);
    const MethodOptionMap = {
        CUMULATIVE_CHANGE: 'Cumulative Change within selected time period',
    };
    const changeTypeOptionMap = {
        POSITIVE: 'Positive',
        NEGATIVE: 'Negative',
        BOTH: 'Both',
    };
    const sensitivityLabels = ['Very Low', '', '', '', '', '', '', '', '', '', 'Very High'];
    const selectedMethodDisplayName = methodType ? MethodOptionMap[methodType] : 'Select a report type';
    const selectedchangeTypeDisplayName = changeType ? changeTypeOptionMap[changeType] : 'Select a change type';
    const handleSliderChange = (event, value) => {
        const label = sensitivityLabels[value - 1];
        setSensitivity(value);
        setSensitivityLabel(label);
    };
    useEffect(() => {
        if (!jobDetails)
            return;
        const jobStartDate = DateTime.fromISO(jobDetails.start_date);
        const jobEndDate = DateTime.fromISO(jobDetails.end_date);
        if (!customStartDate && !customEndDate) {
            setCustomStartDate(jobStartDate);
            setCustomEndDate(jobEndDate);
            onSetStartDate(jobStartDate);
            onSetEndDate(jobEndDate);
        }
        const indices = jobDetails.theme.name === "custom"
            ? jobDetails.job_spectral_indices.map(index => ({
                id: index.id,
                theme_index_key: index.index_key,
                index_key: index.index_key
            }))
            : jobDetails.theme.theme_spectral_indices.map(index => ({
                id: index.spectral_index.id,
                theme_index_key: index.name,
                index_key: index.spectral_index.asi_short_name
            }));
        setSpectralIndices(indices);
        if (aoiRuns) {
            const filteredRuns = aoiRuns.aoiRuns.filter(run => run.aoi_run_results.some(result => result.value === "EXCEEDED"));
            setFilteredRuns(filteredRuns);
        }
    }, [jobDetails, customStartDate, customEndDate, onSetEndDate, onSetStartDate, aoiRuns]);
    const handleValidationDialogClose = () => {
        setIsValidationDialogOpen(false);
        props.onClose();
    };
    const handleIndexChange = (indexId) => {
        setSelectedIndices((prev) => prev.includes(indexId) ? prev.filter(id => id !== indexId) : [...prev, indexId]);
    };
    const handleSelectMethod = (value) => {
        setMethodType(value);
    };
    const handleSelectChange = (value) => {
        setChangeType(value);
    };
    const handleSetCustomDate = (value, period) => {
        if (period === 'start') {
            if (value <= customEndDate || !customEndDate) {
                setCustomStartDate(value);
                onSetStartDate(value);
            }
        }
        else if (period === 'end') {
            if (value >= customStartDate || !customStartDate) {
                setCustomEndDate(value);
                onSetEndDate(value);
            }
        }
    };
    const dispatch = useDispatch();
    const getFooterProps = () => {
        return {
            cancelText: 'Cancel Report',
            submitText: 'Create AOI Report',
            submittingText: 'Creating AOI Report',
            isSubmitDisabled: methodType === null || changeType === null || selectedIndices.length === 0,
            onCancel: () => setIsConfirmCancelDialogOpen(true),
            onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
                const selectedIndicesForSubmit = selectedIndices;
                const startDateForSubmit = customStartDate === null || customStartDate === void 0 ? void 0 : customStartDate.toISO();
                const endDateForSubmit = customEndDate === null || customEndDate === void 0 ? void 0 : customEndDate.toISO();
                try {
                    setValidationMessage("Your report is currently being processed. You will receive an email when it is ready.");
                    setIsValidationDialogOpen(true);
                    dispatch(aoisReportApi.endpoints.getAoiReport.initiate({
                        aoi_id: props.aoiId,
                        startDate: startDateForSubmit,
                        endDate: endDateForSubmit,
                        selectedIndices: selectedIndicesForSubmit,
                        eventType: "ALL_MON",
                        changeType: changeType,
                        methodType: methodType,
                        sensitivity: sensitivity / 10,
                        userEmail: user.email,
                        theme: jobDetails.theme.name,
                    })).unwrap();
                }
                catch (error) {
                    console.error('Error creating AOI report:', error);
                }
            }),
        };
    };
    const confirmClose = () => {
        setIsConfirmCancelDialogOpen(false);
        props.onClose();
    };
    const cancelClose = () => {
        setIsConfirmCancelDialogOpen(false);
    };
    if (isLoading) {
        return React.createElement("div", null, "Loading job details...");
    }
    if (error) {
        return React.createElement("div", null, "Error loading job details");
    }
    const ValidationDialog = ({ isOpen, onClose, message }) => {
        return (React.createElement(Dialog, { title: "Report Requested", open: isOpen, onClose: onClose },
            React.createElement(StyledValidationDialogContent, { className: cx(GridContentStyles.max) },
                message,
                React.createElement(StyledButtonContainer, null,
                    React.createElement(PrimaryButton, { onClick: onClose, size: "small" }, "Close")))));
    };
    return (React.createElement(Dialog, { title: "Request a new AOI Report", onClose: () => setIsConfirmCancelDialogOpen(true), open: props.isOpen, FooterProps: getFooterProps() },
        React.createElement(SecondaryInfoContainer, { className: cx(GridContentStyles.max, GridContentStyles.dialogBodyContent) },
            React.createElement("div", { className: GridContentStyles.max },
                React.createElement(Select, { label: "What type of events are you interested in?", onChange: (event, value) => handleSelectMethod(value), value: selectedMethodDisplayName, placeholder: "Select a report type", required: true }, Object.entries(MethodOptionMap).map(([value, displayName]) => {
                    return (React.createElement(SelectOption, { key: value, value: value }, displayName));
                })),
                React.createElement("br", null),
                React.createElement(Select, { label: "What type of change are you interested in?", onChange: (event, value) => handleSelectChange(value), value: selectedchangeTypeDisplayName, placeholder: "Select a report type", required: true }, Object.entries(changeTypeOptionMap).map(([value, displayName]) => {
                    return (React.createElement(SelectOption, { key: value, value: value }, displayName));
                })),
                React.createElement("br", null),
                React.createElement("div", { className: GridContentStyles.sliderContainer },
                    React.createElement("div", { className: GridContentStyles.sliderWrapper },
                        React.createElement(Slider, { min: 0, max: 10, value: sensitivity, onChange: handleSliderChange, valueLabelFormat: (value) => sensitivityLabels[value], containerClassName: GridContentStyles.mediumSmall, label: "Sensitivity of Change Detection" }))),
                React.createElement("br", null),
                React.createElement(Label, { label: "Select measure(s) for the report", required: true }),
                React.createElement(StyledIndicesContainer, null, spectralIndices.map((index) => (React.createElement(Checkbox, { checked: selectedIndices.includes(index.index_key), label: index.theme_index_key, message: index.index_key, onChange: (_) => handleIndexChange(index.index_key), key: index.index_key }))))),
            React.createElement("div", { className: GridContentStyles.max },
                React.createElement(StyledLabelContainer, null,
                    React.createElement(Label, { label: "Select time period for the report", required: true }))),
            React.createElement(DateRangePicker, { startDate: customStartDate, endDate: customEndDate, onChangeStartDate: (date) => handleSetCustomDate(date, 'start'), onChangeEndDate: (date) => handleSetCustomDate(date, 'end'), minDate: jobDetails ? DateTime.fromISO(jobDetails.start_date) : null, maxDate: jobDetails ? DateTime.fromISO(jobDetails.end_date) : null, StartDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: customStartDate !== null && !customStartDate.isValid,
                    errorMessage: 'Invalid start date',
                }, EndDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: customEndDate !== null && !customEndDate.isValid,
                    errorMessage: 'Invalid end date',
                } })),
        isConfirmCancelDialogOpen && (React.createElement(ConfirmCancelProcessDialog, { processName: "creating the AOI report", isOpen: isConfirmCancelDialogOpen, onClose: cancelClose, onCancelProcess: confirmClose })),
        React.createElement(ValidationDialog, { isOpen: isValidationDialogOpen, onClose: handleValidationDialogClose, message: validationMessage })));
};
