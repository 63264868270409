import { Menu, MenuItem, PrimaryButton } from '@orbica/component-sdk';
import React from 'react';
import { ComparisonResultChart } from '@Components/Charts/ComparisonResult/ComparisonResultChart';
import { ComparisonResultTable } from './ComparisonResultTable';
import Styles from './Stats.scss';
export const Stats = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement("div", { className: Styles.statsContainer },
        React.createElement("br", null),
        React.createElement("div", { className: Styles.title },
            React.createElement("span", null, "Result"),
            React.createElement(PrimaryButton, { size: "small", onClick: handleClick }, "Export"),
            React.createElement(Menu, { id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button',
                } },
                React.createElement(MenuItem, { onClick: props.onExportPdf }, "AOI Change against baseline PDF"),
                React.createElement(MenuItem, { onClick: props.onDownloadAOIBlDataClick }, "AOI Change against baseline Data"),
                React.createElement(MenuItem, { onClick: props.onDownloadSigChngPDFClick }, "Significant Change Report"),
                React.createElement(MenuItem, { onClick: props.onDownloadAllChngPDFClick }, "All Changes PDF"),
                React.createElement(MenuItem, { onClick: props.onDownloadChngDataClick }, "Significant Changes Data"))),
        React.createElement(ComparisonResultTable, Object.assign({}, props, { ContainerProps: {
                className: Styles.statsTableContainer,
            } })),
        React.createElement(ComparisonResultChart, Object.assign({}, props))));
};
